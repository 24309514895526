import React from 'react'
import burgerOpen from '../img/burgerOpen.png'
import burgerClose from '../img/burgerClose.png'
import { useState } from "react"
import '../css/test.css'
import { motion } from "framer-motion"
import fblogo from '../img/fbBlack.png'
import chiroLogo from '../img/chiropractic.png'

const Nav = () => {

  const [isOpen, setIsOpen] = useState(false);

  const openClose = () => {
    if(isOpen){
      setIsOpen(false);
    }
    else{
      setIsOpen(true);
    }
  }


  return (
    <div>
      <div className='navMobBlur'></div>
      <img src={burgerOpen} className="burgerImg" onClick={openClose} alt=""/>
      <motion.div
        layout
        data-isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
        className='burgerContainer'
        >
          <img className='burgerClose' src={burgerClose} alt=""></img>
          <img className='chiroLogo' src={chiroLogo} alt=""></img>
          <div className='navItemsContainer'>
            <a href='#domov'><p>DOMOV</p></a>
            <a href='#cennik'><p>CENNIK</p></a>
            <a href='#onas'><p>O MNE</p></a>
            <a href='https://www.facebook.com/Chiropraktik-na-Z%C3%A1hor%C3%AD-111384211526624/reviews/?ref=page_internal'><p>RECENZIE</p></a>
            <a href='#contact'><p>KONTAKT</p></a>
          </div>
          <a href='facebook.daco.stranka.chiro'>
            <div className='navBottom'>
            <a href='https://www.facebook.com/Chiropraktik-na-Z%C3%A1hor%C3%AD-111384211526624/?ref=page_internal'>
              <img src={fblogo} alt='fblogo' className='fblogoNav'/>
            </a>
          </div>
          </a>
      </motion.div>
      <motion.div
        data-isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
        className='burgerBlur'
        >

      </motion.div>
      
     </div>
  )
}

export default Nav