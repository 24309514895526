import React from 'react';
import '../css/header.css';
import Otvorene from '../components/Otvorene.js';
/*import headBack from '../img/headerBack.png';
import headBackBig from '../img/headerBackBig.png';*/
import Flags from '../components/Flags';
import OnasHead from '../components/OnasHead';
import { useState, useEffect } from 'react';


import headBack from '../img/headerBack.png';
import headBackBig from '../img/headerBackBig.png';


const Header = () => {
  const [pom, setPom] = useState(false);
  useEffect(() => {
    
    var pom = window.innerWidth;
    console.log(pom);
    if(pom > 1023){
      setPom(true);
    }
    else {
      setPom(false);
    }
  })
  
  return (
    <div className='header' id='domov' style={{backgroundImage: pom ? `url(${headBackBig})` : `url(${headBackBig})`}}>
        <OnasHead />
    </div>
  )
}

export default Header