import React from 'react'
import '../css/cennik.css'
import logoDoma from '../img/doma.png'
import logoKlasik from '../img/klasik.png'
import logoExpres from '../img/expres.png'
import logoStart from '../img/start.png'


const Cennik = () => {
  return (
    <div className='cennikContainer' id='cennik'>
        <h2>CENNÍK</h2>
        <p className='cennikNadpis'>Som schopný ošetriť klientov, ktorých váha nepresahuje 160kg.</p>
        <p className='cennikNadpis'>Ošetrenie je možné vykonať po zaplatení danej čiastky a výslovnom súhlase klienta.
Diagnostika a samotné ošetrenie trvá cca 30min - podľa potreby klienta</p>
        <div className='cennikItem prvy'>
            <div className='backgroundItem'></div>
            <div className='prvyModry'></div>
            <h3>Komplexné ošetrenie</h3>
            <img className='cennikItemLogo' src={logoStart} ></img>
            <p className='cennikItemTextPrvy'>PRVÉ NAPRAVENIE A OŠETRENIE CELÉHO POHYBOVÉHO APARÁTU + OŠETRENIE VNÚTORNÝCH ORGÁNOV. UVOĽNENIE ZATUHNUTÉHO SVALSTVA</p>
            <div className='cenaBox'>
                <p className='itemText'>VYŠETRENIE PO-SO</p>
                <p className='itemCena'>70€</p>
            </div>
            <div className='cenaBox'>
                <p className='itemText'>VYŠETRENIE V NEDEĽU</p>
                <p className='itemCena'>100€</p>
            </div>
            <p className='cennikitemTextDruhy'>je určené pre nových klientov. Prvé, vstupné vyšetrenie , 
komplexné ošetrenie a zaradenie do databázy.</p>
        </div>
            <div className='cennikItem druhy'>
            <div className='backgroundItem'></div>
            <div className='prvyModry'></div>
            <h3>Komplexné ošetrenie</h3>
            <img className='cennikItemLogo' src={logoKlasik} ></img>
            <p className='cennikItemTextPrvy'>Ďalšie napravenie a ošetrenie celého pohybového
aparátu + ošetrenie vnútorných orgánov. uvoľnenie
zatuhnutého svalstva.</p>
            <div className='cenaBox'>
                <p className='itemText'>VYŠETRENIE PO-SO</p>
                <p className='itemCena'>60€</p>
            </div>
            <div className='cenaBox'>
                <p className='itemText'>VYŠETRENIE V NEDEĽU</p>
                <p className='itemCena'>100€</p>
            </div>
            <p className='cennikitemTextDruhy'>ošetrenie je určené pre klientov, ktorí absolvovali
terapiu aspoň raz v priebehu posledných 12 mesiacov.</p>
        </div>
            <div className='cennikItem treti'>
            <div className='backgroundItem'></div>
            <div className='prvyModry'></div>
            <h3>Komplexná terapia</h3>
            <img className='cennikItemLogo' src={logoExpres} ></img>
            <p className='cennikItemTextPrvy'>komplexné ošetrenie celého pohybového 
aparátu + ošetrenie vnútorných orgánov.
</p>
            <div className='cenaBox single'>
                <p className='itemText'>VYŠETRENIE DO 12 HODÍN</p>
                <p className='itemCena'>100€</p>
            </div>
            <p className='cennikitemTextDruhy druhySingle'>ošetrenie je určené pri akútnej potrebe rýchleho 
vyšetrenia a expresné pridelenie termínu v čase do
12 hodín.</p>
        </div>
        <div className='cennikItem stvrty'>
            <div className='backgroundItem'></div>
            <div className='prvyModry'></div>
            <h3>Komplexná terapia</h3>
            <img className='cennikItemLogo' src={logoDoma} ></img>
            <p className='cennikItemTextPrvy'>komplexné ošetrenie celého pohybového 
aparátu + ošetrenie vnútorných orgánov.
</p>
            <div className='cenaBox single'>
                <p className='itemText'>VYŠETRENIE</p>
                <p className='itemCenaDoma'>80€ + 0.50€/KM</p>
            </div>
            <p className='cennikitemTextDruhy druhySingle'>OšETRENIE JE URčENé PRI POTREBE VYšETRENIA V POHODLí Vášho
DOMOVA. môžE ísť O VSTUPNé VYšETRENIE ALE AJ ĎALšIE 
NAPRAVENIE.</p>
        </div>
    </div>
  )
}

export default Cennik