import React from 'react'
import '../css/onasHead.css'
import arrow from '../img/arrowRight.png'
import { useEffect, useState, useContext } from "react"

import { LanguageContext } from '../Contexts/LanguageContext'


const OnasHead = () => {

  const { lang } = useContext(LanguageContext);
  const [headerText, setHeaderText] = useState('');
  const [aboutText, setAboutText] = useState('');
  const [aboutTitle, setAboutTitle] = useState('');
  const [citajViacText, setCitajViacText] = useState('');


  useEffect(() => {
    if(lang === 'SK'){
      setHeaderText('O MNE');
      setAboutTitle('CHIROPRAKTIK - ĽIEČITEĽ')
      setAboutText('DOBRÝ DEŇ DRAHÍ PRIATELIA, MOJE MENO JE FRANTIŠEK LALIŠ A SOM CHIROPKRATIK, LIEČITEĽ POHYBOVÉHO APARÁTU ...');
      setCitajViacText('ČÍTAJTE VIAC');
    }
    else if(lang === 'EN'){
      setHeaderText('ABOUT');
      setAboutTitle('CHIROPRACTOR - HEALER')
      setAboutText('HELLO DEAR FRIENDS, MY NAME IS FRANTISEK LALIS AND IM CHIROPRACTOR, HEALER OF MUSCULOSKELETAL SYSTEM ...' );
      setCitajViacText('READ MORE');
    }
    else{
      setHeaderText('ÜBER');
      setAboutTitle('CHIROPRAKTOR - HEILER')
      setAboutText('GUTEN TAG LIEBE FREUNDE. MEINE NAME IST FRANCIS LALIS UND ICH BIN CHIROPRAKTOR, BEWEGUNGSHEILER GERÄTE ...');
      setCitajViacText('WEITERLESEN');
    }
  });
  

  return (
    <div className='onashead'>
        <div className='onasBlurHead'></div>
        <div className='onasHeadTextContainer'>
          <p className='onasBlurText'>{headerText}</p>
        </div>
        <p className='onasInfoTitle'>{aboutTitle}</p>
        <p className='onasInfoText'>{aboutText}
        </p>
        <a href='#onas'><div className='citajteViac'>
            <p>{citajViacText}</p>
            <img src={arrow}></img>
        </div>
        </a>
        <div className='telcBlur'></div>
        <div className='telContainer'>
          <a href='tel:+421905777779'>
            <p className='telC'>0905 777 779</p>
          </a>
        </div>
    </div>
  )
}

export default OnasHead
