import { GoogleMap, withScriptjs, withGoogleMap, Marker} from 'react-google-maps';
import '../css/mapa.css'
import fblogo from '../img/fbBlack.png';

function Map() {
  return(
    <GoogleMap 
      defaultZoom={12} 
      defaultCenter={{lat: 48.6558923, lng: 17.0856165}}
    >
      <Marker position={{lat: 48.6558923, lng: 17.0856165}} />
    </GoogleMap>
  );
}
const WrappedMap = withScriptjs(withGoogleMap(Map))

function Mapa() {
  return (
    <div className="mapContainer">
      <h3>KONTAKT</h3>
      <p className='name'>František Lališ</p>
      <div className='container'>
      <WrappedMap googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${
          process.env.REACT_APP_GOOGLE_KEY='AIzaSyBMBXNZDItND6WuMeEcEBoY3NU9-ZSDvHM'
        }`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `250px` }} />}
          mapElement={<div style={{ height: `100%` }} />}/>
          </div>
          <div className='bottomContactContainer'>
            <p className='bottomContact'>Adresa: Poštová Ulica, 908 43, Čáry</p>
            <p className='bottomContact'>Email: info@chiropraktiknazahori.sk</p>
            <p className='bottomContact'>Telefón: +421 905 777 779</p>
            <a href='https://www.facebook.com/Chiropraktik-na-Z%C3%A1hor%C3%AD-111384211526624/?ref=page_internal'>
              <img src={fblogo} alt='fblogo' className='fblogodole'/>
            </a>
          </div>
   </div>
  );
}export default Mapa;