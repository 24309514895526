import React from 'react'
import '../css/contact.css'

import { useRef, useState } from 'react';

const Contact = () => {
  const form = useRef();
  const [result, setResult] = useState(false)

  return (
    <div>
        <form ref={form} className='formular' method='POST' action='mailTo.php'>
            <h3>KONTAKTUJTE MA</h3>
            <input placeholder='MENO' type='name' name='fname' required/>
            <input className="right" placeholder='PRIEZVISKO' type='surname' name='lname'/>
            <textarea placeholder='VÁŠ TEXT...' rows='5' cols='50' name='message' required></textarea>
            <input placeholder='TELEFON' type='phone' name='phone' required/>
            <input className="right" placeholder='EMAIL' type='email' name='email' required/>
            <input className='submitButton' type='submit' value='ODOSLAŤ' name='submit'></input>
            
        </form>
    </div>
  )
}

export default Contact
