import React from 'react'
import '../css/clanok.css'
import { motion } from "framer-motion"
import { useState, useEffect } from 'react'
import clanokLogo from '../img/rukaInfo.png'
import arrowRight from '../img/arrowRight.png'

const Clanok = () => {

  const [isOpen, setIsOpen] = useState(false);

  const test = () => {
    var div = document.getElementsByClassName('clanokContainer');
    if(isOpen){
      div[0].scrollTop = 0;
    }
    setIsOpen(!isOpen);
  }

  return (
   
      <motion.div
        layout
        data-isOpen={isOpen}
        onClick={test}
        className='clanokContainer'
        >
          <motion.div 
            layout
            className='clanokChild'> 
              <img src={clanokLogo} className='clanokLogo'/>
              <h2 id='nadpis'>KEDY A AKO VÁM MÔŽEM POMÔCŤ</h2>
              <div className='clanok'>
                <p>VEĽA STARŠÍCH A DOKONCA UŽ AJ MLADÝCH ĽUDÍ TRPÍ BOLESŤAMI CHRBTA, KRÍŽOV ALEBO KĹBOV. STÁVAJÚ SA ZÁVISLÝMI NA LIEKOCH TLMIACICH ICH BOLESTI. AVŠAK TIETO IBA POTLÁČAJÚ PRÍZNAKY, ZATIAĽ ČO SKUTOČNÁ PRÍČINA BOLESTI SA ČASTO NEZISTÍ A NELIEČI. A PRÁVE AJ TÝMTO ĽUĎOM MÔŽE CHIROPRAKTIK VÝRAZNE POMÔCŤ NAPRAVENÍM KĹBOV A STAVCOV</p><motion.p className='vsuvka' data-isOpen={isOpen}>čítajte viac...</motion.p>
                <p>Chiropraktika rieši problémy a posuny stavcov a medzistavcovej platničky v chrbtici, tie totiž môžu vyvolať bolesti nielen v samotnej chrbtici a okolitých končatinách, ale aj vo vnútorných orgánoch, ktoré sú nervovou sústavou s chrbticou prepojené. Vo veľa prípadoch môžu tieto problémy spôsobiť slabší alebo len minimálny prenos neurónov v nervovej sústave k jednotlivým orgánom, čo môže vyvolať dokonca aj príčinu vzniku astmy, vysokého tlaku, dýchavičnosti, žalúdočných problémov, migrény, zácpi, menštruačný obtiaží, neplodnosti a veľa iných.</p>
                <p>Chiropraktik sa snaží svojimi skúsenými rukami napraviť posunutie chrbtových a krčných stavcov, ramenných, bederných, kolenných kĺbov, panvovej a krížnej kosti, klúčnej kosti, rebier, zápästia, kolien, kotníkov, ale aj uvoľnenie svalov a šliach. Chiropraktik, pokiaľ má náležité skúsenosti, môže liečiť pacientov všetkých vekových skupín, počínajúc kojencami a končiac staršími osobami. Chiropraktik môže pomôcť väčšine pacientov, ktorý trpia nejakým druhom bolesti pohybového aparátu, kostrového svalstva a obzvlášť bolesťami chrbta v krížoch.</p>
                <p>Bežnou obtiažou, ktorú môže chiropraktik liečiť, je bolesť hlavy. Vo väčšinu týchto prípadov ju spôsobuje buď napätie sťahujúcich sa krčných svalov, posunutie krčných stavcov a medzistavcových platničiek po rôznych úrazoch, alebo dokonca aj stres a žalúdočné problémy. Chiropraktici pomáhajú aj aktívnym alebo pasívnym športovcom, ktorí trpia množstvom zranení vrátane natiahnutých svalov, stiahnutých svalov (tzv. koňarov), vyvrtnutých kĺbov, vykĺbených kolien, vykĺbených tenisových a golfových lakťov.</p>
              </div>              
          </motion.div>
      </motion.div>
      
  )
}

export default Clanok