import React from 'react'
import '../css/otvorene.css'
import sipkaOpen from '../img/sipkaOpen.png' 
import sipkaClosed from '../img/sipkaClosed.png' 
import { useEffect, useState, useContext } from "react"
import { motion } from "framer-motion"
import { LanguageContext } from '../Contexts/LanguageContext'


const Otvorene = () => {

const [jeOtvorene, setJeOtvorene] = useState(false);
const [string, setString] = useState('');
const [openLayout, setOpenLayout] = useState();
const [imgUrl, setImgUrl] = useState('');
const [classString, setClassString] = useState("closed");

const { lang } = useContext(LanguageContext);


useEffect(() => {
    var hodina = new Date().getHours();
    
    if(hodina >= 6 && hodina < 24){
        setJeOtvorene(true);
        if(lang === 'SK'){
            setString('OTVORENÉ');
        }
        else if(lang === 'EN'){
            setString('OPEN');
        }
        else if(lang === 'DE'){
            setString('OFFEN');
        }
        setClassString('openClock');
    }
    else{
        setJeOtvorene(false);
        if(lang === 'SK'){
            setString('ZATVORENÉ');
        }
        else if(lang === 'EN'){
            setString('CLOSED');
        }
        else if(lang === 'DE'){
            setString('GESCHLOSSEN');
        }
        
        setClassString('closeClock');
    }
  });


  const dni = [
    { den: 'Po', otvorene: true, odKedy: '6:00', doKedy: '24:00'},
    { den: 'Ut', otvorene: true, odKedy: '6:00', doKedy: '24:00'},
    { den: 'St', otvorene: true, odKedy: '6:00', doKedy: '24:00'},
    { den: 'Štv', otvorene: true, odKedy: '6:00', doKedy: '24:00'},
    { den: 'Pia', otvorene: true, odKedy: '6:00', doKedy: '24:00'},
    { den: 'So', otvorene: true, odKedy: '6:00', doKedy: '24:00'},
    { den: 'Ne', otvorene: true, odKedy: '6:00', doKedy: '24:00'},
  ];

  return (

    <motion.div
                layout
                data-openLayout={openLayout}
                initial={{ borderRadius: 10 }}
                className={classString + ' parent'}
                onClick={() => setOpenLayout(!openLayout)}>
                <motion.div layout className="child">
                    <div className={'otvoreneContainer ' + classString}>
                        <div className='sipkaObdlznik'>
                            <img src={jeOtvorene ? sipkaOpen : sipkaClosed} alt='point' className='sipka' /> 
                            <p className='otvoreneText'>{string}</p>
                        </div>
                    </div>
                    <div className="openContainer">
                        {dni.map((e) => {
                            if(e.otvorene){
                                return(
                                <div className="openRow">
                                    <p className='den'>{e.den}:</p>
                                    <p className="time">{e.odKedy} - {e.doKedy}</p>
                                    
                                </div>
                                )
                            }
                            else{
                                return(
                                <div className="openRow">
                                    <p>{e.den}:</p>
                                    <p>Zatvorene</p>
                                </div>
                                )
                            }
                        })}
                    </div>
                </motion.div>
    </motion.div>
    
  )
}

export default Otvorene