import './App.css'
import Header from './components/Header'
import { useState, useEffect } from "react"
import Nav from './components/Nav'
import Flags from './components/Flags'
import React from 'react';
import { LanguageContext } from './Contexts/LanguageContext'
import Clanok from './components/Clanok'
import Cennik from './components/Cennik'
import Onas from './components/Onas'
import Contact from './components/Contact'
import Mapa from './components/Mapa'
import NavDesktop from './components/NavDesktop'
import Otvorene from './components/Otvorene'

window.lang = 'SK';


function App() {
  const [lang, setLang] = useState('SK');
  const [rozlisenie, setRozlisenie] = useState(false);
  useEffect(() => {
    var pom = window.innerWidth;
    if(pom > 719){
      setRozlisenie(true);
    }
    else {
      setRozlisenie(false);
    }
  })

  return (
    <LanguageContext.Provider value={{ lang, setLang }}>
    <div className="App">
      <Header />
      {rozlisenie ? <NavDesktop /> : <Nav />}
      <Clanok />
      <Cennik />
      <Onas />
      <div className='bottomContainer' id='contact'>
        <Contact />
        <Mapa />
      </div>
      <small className='copyright'>© Copyright 2022 František Lališ, by RiskaDev X Comor Design</small>
    </div>
    <Otvorene />
    <Flags />
    </LanguageContext.Provider>
  );
}

export default App;
