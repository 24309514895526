import React from 'react'
import '../css/onas.css'

const Onas = () => {
  return (
    <div className='onasContainer' id='onas'>
        <h2>O MNE</h2>
        <p>CHIROPRAKTIK - LIEčITEľ</p>
        <p>Dobrý deň, drahí priatelia</p>
        <p>Moje meno je František Lališ a som chiropraktik - liečiteľ pohybového aparátu z čárov. Špecializujem sa na tie najťažšie stavy, pri ktorých už iný chiropraktik, fyzioterapeut, poprípade lekár nevedia pomôcť. Pokiaľ máte problém s pohybovým aparátom (krčná chrbtica, chrbát, ramená, rebrá, kríže, kolená...), neváhajte ma kontaktovať a ja vám rád pomôžem. </p>
        <p>"SOM TU PRE VÁS"</p>
    </div>
  )
}

export default Onas