import React from 'react'
import '../css/navDesktop.css'
import fbLogoBlack from '../img/fbBlack.png'
import fbLogoWhite from '../img/facebookLogo.png'
import fbLogoBlue from '../img/fbLogoBlue.png'
import { useState, useEffect } from 'react'
import { motion } from "framer-motion"
 
const NavDesktop = () => {

    const [small, setSmall] = useState(false);
    const [fbHover, setFbHover] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setSmall(window.pageYOffset > 150)
      );
    }
  }, []);

  return (
    <>
    <motion.div
      layout
      className={`desktopNavBlur${
        small ? " cool" : ""
    }`}>

    </motion.div>
    <motion.div 
    layout
    className={`desktopNavContainer${
        small ? " small" : ""
    }`}>
        <a href='#domov'><p>DOMOV</p></a>
        <a href='#cennik'><p>CENNIK</p></a>
        <a href='#onas'><p>O MNE</p></a>
        <a href='https://www.facebook.com/Chiropraktik-na-Z%C3%A1hor%C3%AD-111384211526624/reviews/?ref=page_internal'><p>RECENZIE</p></a>
        <a href='#contact'><p>KONTAKT</p></a>
        <a href='https://www.facebook.com/Chiropraktik-na-Z%C3%A1hor%C3%AD-111384211526624/?ref=page_internal'>
            <img src={fbHover ? fbLogoBlue : small ? fbLogoBlack : fbLogoWhite} alt='fblogo' className='fblogohore' onMouseEnter={() => {setFbHover(true)}} onMouseLeave={() => {setFbHover(false)}}/>
        </a>
       
        
    </motion.div>
    </>
  )
}

export default NavDesktop