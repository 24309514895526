import React from 'react'
import '../css/flags.css'
import skFlagImg from '../img/skFlag.png'
import enFlagImg from '../img/enFlag.png'
import deFlagImg from '../img/deFlag.png'
import { motion } from "framer-motion"
import { useState, useContext } from "react"
import { LanguageContext } from '../Contexts/LanguageContext'


const Flags = () => {

    const {setLang, lang} = useContext(LanguageContext);

    const [isOpenFlag, setIsOpenFlag] = useState(false);
    
    const changeToSk = () => {
      window.lang = 'SK';
      document.documentElement.setAttribute("lang", 'sk');
      setLang('SK');
  }

  const changeToEn = () => {
      window.lang = 'EN';
      document.documentElement.setAttribute("lang", 'en');
      setLang('EN');
  }

  const changeToDe = () => {
      window.lang = 'DE';
      document.documentElement.setAttribute("lang", 'de');
      setLang('DE');
  }
 

    return (
      <div className='langContainer'>
      <motion.div
          layout
          data-isOpenFlag={isOpenFlag}
          initial={{ borderRadius: 10 }}
          className={lang + ' parent2'}
          onClick={() => setIsOpenFlag(!isOpenFlag)}>
          <motion.div layout className={lang + " child2"}>
              <p onClick={lang === 'SK' ? changeToSk : lang === 'EN' ? changeToEn : changeToDe}>{lang === 'SK' ? 'SK' : lang === 'EN' ? 'EN' : 'DE'}</p>
              <p onClick={lang === 'SK' ? changeToEn : lang === 'EN' ? changeToSk : changeToSk}>{lang === 'SK' ? 'EN' : lang === 'EN' ? 'SK' : 'SK'}</p>
              <p onClick={lang === 'SK' ? changeToDe : lang === 'EN' ? changeToDe : changeToEn}>{lang === 'SK' ? 'DE' : lang === 'EN' ? 'DE' : 'EN'}</p>
          </motion.div>
      </motion.div>
      <img className='flagImg' src={lang === 'SK' ? skFlagImg : lang === 'EN' ? enFlagImg : deFlagImg} onClick={() => setIsOpenFlag(!isOpenFlag)}/>
  </div>
    )
}

export default Flags